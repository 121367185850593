<style>


.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  height: 2.5em;
  width: 100%;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.vue__time-picker {
  width: 100%;
}
</style>
<template>
  <div class="px-7 py-6" style="background-color: #e5e5e5">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>

     <v-card>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form ref="schoolForm" style="margin-top: 40px">
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <h3
            style="
              margin-left: 518px;
              color: rgb(56, 34, 122);
            "
          >
            School Info
          </h3>
                </v-col>
              </v-row>
              <br>
              <br>
              <br>
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="contactPersonName"
                    label="Contact Person Name"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                    v-model="email"
                    label="Contact Person Email"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.mobile]"
                    v-model="mobile"
                    type="number"
                    label="Contact Person Phone"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3" justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="schoolName"
                    :rules="[rules.required]"
                    label="School Name"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="totalStudents"
                    label="Total Students"
                    color="#7253CF"
                    class="formFields"
                    type="number"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="leadGroup"
                    label="Lead Group"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="city"
                    :rules="[rules.required]"
                    label="City"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[rules.required]"
                    v-model="state"
                    :items="statesList"
                    item-text="state_name"
                    item-value="id"
                    label="State"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <v-textarea
                    outlined
                    dense
                    v-model="shippingAddress"
                    :rules="[rules.required]"
                    label="Shipping Address"
                    color="#7253CF"
                    class="formFields"
                    row-height="8"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-btn
                  class="submit-btn"
                  :loading="formLoading"
                  dark
                  color="#38227A"
                  @click="submitSchoolForm"
                  >Add school</v-btn
                >
                <br />
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SALES_REP } from "@/constants/ModuleKeys";
import moment from "moment";
import RULES from "@/common/fieldRules";
import {
  API_MASTER_GET_STATES_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_SCHOOLINFO_ADD_SCHOOL,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_USER_GET_DISTRICT_LIST,
  API_MASTER_GET_MESSAGE,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditViewSchoolModal",
  props: ["DistrictFormData"],
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      previewImage: null,
      LogoImgFile: {},
      schoolName: "",
      salesRep: null,
      salesRepList: [],
      city: "",
      state: null,
      statesList: [],
      contactPersonName: "",
      role: "",
      email: "",
      mobile: null,
      shippingAddress: "",
      leadGroup: "",
      district: -1,
      totalStudents: null,
      districtList: [],
      SpecialMessage: "",
      short_school_name: "",
      totalcharacter: 0,
      maxchar: 20,
      menu: false,
      menu1: false,
      menu2: false,
      low_goal: "",
      low_goal_purpose: "",
      top_goal: "",
      top_goal_purpose: "",
      middle_goal: "",
      middle_goal_purpose: "",
      pre_registration_date:"",
      pre_registration_time:"",
      kickoff_starttime:"",
      golden_ball_date:"",
      golden_ball_time:"",
      graduation_starttime:"",
      raising_money_purpose:"",
      principal_video:false,
      teacher_wizfit_challenge:false,
      teacher_training_date:"",
      teacher_training_time:"",
      teacher_list:false,
      video_episodes_plan:"",
      arrange_sponsorship:false

    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewSchoolModal({ show: value });
      },
    },
    type() {
      return this.$store.state.schoolManagement.addEditViewModal.type;
    },
    districtID() {
      return this.$store.state.schoolManagement.addEditViewModal.districtID;
    },
    toastMessage() {
      return "School Added";
    },
    dialogTitle() {
      return "Add new School";
    },
    formattedPreregistrationDate: {
      get() {
        if (this.pre_registration_date) {
          return moment(this.pre_registration_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgoldenballDate: {
      get() {
        if (this.golden_ball_date) {
          return moment(this.golden_ball_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedTrainingDate: {
      get() {
        if (this.teacher_training_date) {
          return moment(this.teacher_training_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        setTimeout(() => {
          this.modalOpenCallback();
        }, 100);
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file.size < 1000000) {
        this.LogoImgFile = payload;
        if (file) {
          this.previewImage = URL.createObjectURL(file);
          URL.revokeObjectURL(file); // free memory
        } else {
          this.previewImage = null;
        }
      } else {
        console.log("image validation error");
        this.showToast({
          message: "Logo file size must be less than 10 MB."
,
          color: "e",
        });
      }
    },
    /**
     * Callback handler on open of modal
     */
    modalOpenCallback() {
      // console.log("modalOpenCallback");
      this.loading = true;

      this.getStateList();
    },
    /**
     * Callback handler on close of modal
     */
    modalCloseCallback() {
      this.previewImage = null;
      this.LogoImgFile = {};
      this.schoolName = "";
      this.salesRep = null;
      this.salesRepList = [];
      this.city = "";
      this.state = -1;
      this.statesList = [];
      this.contactPersonName = "";
      this.role = "";
      this.email = "";
      this.mobile = null;
      this.loading = false;
      this.formLoading = false;
      this.shippingAddress = "";
      this.leadGroup = "";
      this.district = -1;
      this.districtList = [];
      this.SpecialMessage = "";
    },
    /**
     * onChange handler for District
     */
    onDistrictChangeHandler(value) {
      var selectedDistrict = this.districtList.filter(
        (district) => district.id === value
      )[0];
      this.city = selectedDistrict.city_name;
      this.state = selectedDistrict.state_id;
    },
    /**
     * GET State List
     */
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET Sales-Rep List
     */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.sales_rep_list);
        self.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getDistrictList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET District List
     */
    getDistrictList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.district_list);
        self.districtList = data.district_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        if (self.type === "view" || self.type === "edit") {
          self.getSchoolDetails();
        } else {
          self.setFormDataParams();

          self.getSpecialMessage();
        }
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_DISTRICT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        // console.log(res,"special message")
        this.SpecialMessage = res.data.special_message_list[0].message;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "school";
      formData["message_type"] = "special_message";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSchoolDetails() {
      const self = this;

      const successHandler = (res) => {
        let data = res.data;
        // console.log("success ", data.school_detail);

        // this.previewImage = null;
        this.schoolName = data.school_detail.school_name;
        this.salesRep = data.school_detail.sales_rep;
        this.city = data.school_detail.city_name;
        this.contactPersonName = data.school_detail.user_name;
        this.role = data.school_detail.role;
        this.email = data.school_detail.user_email;
        this.mobile = data.school_detail.user_phone;
        this.state = data.school_detail.state;
        this.previewImage = data.school_detail.logo_url;
        this.shippingAddress = data.school_detail.shipping_address;
        this.leadGroup = data.school_detail.lead_group;
        this.district = data.school_detail.district;
        this.SpecialMessage = data.school_detail.special_message;
        this.totalStudents = data.school_detail.total_student;

        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["school_id"] =
        this.$store.state.schoolManagement.addEditViewModal.schoolID;
      console.log({ formData });

      /**
       * API Call for GET school detail
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    
    submitSchoolForm() {
      if (this.$refs.schoolForm.validate()) {
        const self = this;
        self.formLoading = true;
        // console.log("Form validated");

        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          this.$emit("reload");
          location.reload();
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };

        const failureHandler = (res) => {
          // console.log("Failure ", res);
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append("school_name", this.schoolName);
        formData.append("user_name", this.contactPersonName);
        formData.append("user_email", this.email);
        if (this.mobile) formData.append("user_phone", this.mobile);
        formData.append("role", "Host");
        formData.append("city_name", this.city);
        formData.append("state_id", this.state);
        formData.append("lead_group", this.leadGroup);
        formData.append("shipping_address", this.shippingAddress);
        formData.append("total_student", this.totalStudents);
        Axios.request_POST(
          API_ADMIN_SCHOOLINFO_ADD_SCHOOL,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    setFormDataParams() {
      if (this.$route.params["id"]) {
        console.log(this.$route.params["id"]);
        this.district = this.$route.params["id"];
        var selectedDistrict = this.districtList.filter(
          (district) => district.id === this.district
        )[0];
        this.city = selectedDistrict.city_name;
        this.state = selectedDistrict.state_id;
        this.salesRep = this.$route.params[SALES_REP];
        // console.log("in toggle");
      }
    },
    charCount: function () {
      if (this.short_school_name.length > this.maxcharacter) {
        alert("max char limit is 30 character");
        return "";
      } else {
        this.totalcharacter = this.short_school_name.length;
      }
    },
  },
  mounted() {
    this.getStateList();
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 315%;
  color: #ffffff;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.cardActions {
  position: sticky;
  bottom: 0px;
  width: 500px;
  background-color: white;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}

.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
}

.file-input-col {
  margin-left: 13px;
}
img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .v-card__title {
    width: 100%;
  }
  .cardActions {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
